<template>
<v-layout class="fill-height" justify-center style="background: rgba(0, 0, 0, 0.4);height: 100%;">
    <el-card >
        <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="数据库配置" name="first">
                <vDbconfig/>
            </el-tab-pane>
            <el-tab-pane label="平台配置" name="second">
                <vSyconfig/>
            </el-tab-pane>
        </el-tabs>
        <v-card-actions>
            <v-btn block  
            color="info" 
            style="color:#fff"
            v-on:click="logout">
                <span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.loginOut')}}</span>
            </v-btn>
        </v-card-actions>
    </el-card>
</v-layout>
</template>
<script>
import * as api from '../../api'
import vDbconfig from './DbConfig.vue'
import vSyconfig from './SystemConfig.vue'
    export default {
        components: {
			vDbconfig,
            vSyconfig,
		},
        data() {
            return {
                activeName :"first"
            }
        },
        methods: {
            logout(){
                this.$http.get(api.default.auth.logoutUrl).then(resp => {
                    window.sessionStorage.removeItem('authUser');
                    window.sessionStorage.removeItem('type');
                    window.sessionStorage.removeItem('contact');
                    window.sessionStorage.removeItem('name');
                    window.sessionStorage.removeItem('isAuthed');
                    this.$router.push('login');
                }).finally(resp => {
                    
                })
            },
        }
    }
</script>
<style>
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
  width:420px;
}
</style>
<template>
    <div>
        <div slot="header" class="clearfix">
            <h4 style="display:inline-block">mysql数据库配置</h4>
            <h4 v-if="connetstatus" style="display:inline-block;float:right">连接成功
                <i class="dotClass" style="background-color:springgreen"></i>
            </h4>
            <h4 v-if="!connetstatus" style="display:inline-block;float:right">连接失败
                <i class="dotClass" style="background-color:red"></i>
            </h4>
            <v-card-actions>
                <v-btn block  
                color="success" 
                style="color:#fff" 
                v-on:click="handleOpen"
                :disabled="!connetstatus||isinitdb">
                    <span style="color:#fff">{{butname}}</span>
                </v-btn>
            </v-card-actions>
            <v-form>
                <v-text-field
                v-model="loginFormData.dbhost"
                label="数据库地址"
                ></v-text-field>
                <v-text-field
                v-model="loginFormData.dbport"
                label="数据库端口号"
                ></v-text-field>
                <v-text-field
                v-model="loginFormData.dbusername"
                label="数据库用户名"
                ></v-text-field>
                <v-text-field
                v-model="loginFormData.dbpassword"
                label="数据库密码" 
                type="password"></v-text-field>
                <v-text-field
                v-model="loginFormData.dbname"
                label="数据库名称"></v-text-field>
            </v-form>
            <v-card-actions>
                <v-btn block  
                color="indigo_lighten_1" 
                style="color:#fff"
                :disabled="connetstatus"
                v-on:click="onSubmitForm">
                    <span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.saveBtn')}}</span>
                </v-btn>
            </v-card-actions>
        </div>
        <v-dialog v-model="dialog" max-width="290">
            <v-card>
                <v-card-title class="headline">
                    <v-icon large color="red">warning</v-icon>
                    {{title}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="handleClose">
                        {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                    </v-btn>
                    <v-btn color="red darken-1" @click="IntoDB" :loading="loading">
                        {{$vuetify.lang.t('$vuetify.portalManage.confirm')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import * as api from '../../api'

    export default {
        data() {
            return {
                timer: "",
                loginFormData: {
                    dbhost :"",
                    dbport :"",
                    dbusername :"",
                    dbpassword :"",
                    dbname :"",
                },
                butname:"初始化数据库",
                isinitdb:false,
                connetstatus:false,
                loading:false,
                title:"警告!该操作会重置数据库数据，请在第一次运行时执行",
                dialog:false
            }
        },
        created () {
            this.getConfigInfo()
        },
        methods: {
            getConfigInfo(){
                this.$http.get(api.default.editconfig.getconfigInfo).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    this.loginFormData.dbhost = resp.data.dbhost
                    this.loginFormData.dbport = resp.data.dbport
                    this.loginFormData.dbusername = resp.data.dbusername
                    this.loginFormData.dbname = resp.data.dbname
                    this.connetstatus = resp.data.connetstatus
                })
            },
            getConnetStatus(){
                this.$http.get(api.default.editconfig.getconfigInfo).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    this.connetstatus = resp.data.connetstatus
                }).finally(resp => {
                    
                })
            },
            //保存配置
            onSubmitForm(e) {
                this.$http.post(api.default.editconfig.saveconfigInfo,this.loginFormData).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    this.getConnetStatus()
                })
            },
            IntoDB(){
                this.$http.post(api.default.editconfig.setInitDB).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    this.timer = setInterval(this.getstatus, 1000);
                    this.handleClose()
                })
            },
            handleOpen(){
                this.dialog = true
            },
            handleClose(){
                this.dialog = false
            },
            getstatus(){
                this.$http.get(api.default.editconfig.statusInitDB).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    if(resp.errmsg == ""){
                        this.isinitdb=false
                        this.butname="初始化完成"
                        clearInterval(this.timer);
                    }else{
                        this.isinitdb=true
                        this.butname="数据库初始化中,请稍等"
                    }
                })
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
    }
</script>
<style lang="scss">
    .dotClass {
        width:10px;
        height:10px;
        border-radius:50%;
        display: inline-block;
    }
</style>
<template>
        <div slot="header" class="clearfix">
            <h4 style="display:inline-block">平台配置</h4>
            <h5 style="display:inline-block">（单门店平台开启的情况下平台将不显示集团、门店、设备等无用模块）</h5>
            <v-form>
                <v-checkbox
                v-model="loginFormData.systemtype"
                label="单门店平台" 
                type="checkbox"></v-checkbox>
                <v-text-field
                v-model="loginFormData.group_name"
                label="默认集团名称"
                ></v-text-field>
                <v-text-field
                v-model="loginFormData.domain"
                label="默认集团域名"
                ></v-text-field>
                <v-text-field
                v-model="loginFormData.shop_name"
                label="默认场所名称"
                ></v-text-field>
            </v-form>
            <v-card-actions>
                <v-btn block  
                color="indigo_lighten_1" 
                style="color:#fff"
                v-on:click="onSubmitForm">
                    <span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.saveBtn')}}</span>
                </v-btn>
            </v-card-actions>
        </div>
</template>
<script>
    import * as api from '../../api'
	import { Notification } from 'element-ui';
    export default {
        data() {
            return {
                timer: "",
                loginFormData: {
                    group_name :"",
                    domain :"",
                    shop_name :"",
                    systemtype :"",
                },
            }
        },
        created () {
            this.getConfigInfo()
        },
        methods: {
            getConfigInfo(){
                this.$http.get(api.default.editconfig.getsystemInfo).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    this.loginFormData.group_name = resp.data.group_name
                    this.loginFormData.domain = resp.data.domain
                    this.loginFormData.shop_name = resp.data.shop_name
                    this.loginFormData.systemtype = resp.data.systemtype
                })
            },
            //保存配置
            onSubmitForm() {
                this.$http.post(api.default.editconfig.changesystem,this.loginFormData).then(resp => {
                    if(resp.errno !== 0){
                        return false
                    }
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.common.success')
                    });
                })
            },
        },
    }
</script>
<style lang="scss">
    .dotClass {
        width:10px;
        height:10px;
        border-radius:50%;
        display: inline-block;
    }
</style>